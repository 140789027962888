import {FaYoutube, FaTwitter, FaDiscord} from "react-icons/all";

const Footer = () => {
    return (
        <>
            <div className="w-full h-full px-16 mx-auto mt-12 mb-6">
                <span className="text-gold text-xl">Paul Bartzsch</span>
                <a href="https://discord.gg/msBpDWPWvH" target="_blank" aria-label="Discord" rel="noreferrer">
                    <FaDiscord color="white" size="24" className="mr-0 w-8 h-7 ml-auto float-right pl-2"/>
                </a>
                <a href="https://www.youtube.com/channel/UCRuX-jlPie0tnIA9SGwUb7w" target="_blank" aria-label="YouTube" rel="noreferrer">
                    <FaYoutube color="white" size="24" className="mr-0 w-8 h-7 ml-auto float-right pl-2"/>
                </a>
                <a href="https://twitter.com/PlobhLIVE" target="_blank" aria-label="Twitter" rel="noreferrer">
                    <FaTwitter color="white" size="24" className="mr-0 w-5 h-7 ml-auto float-right"/>
                </a>
                <div className="border-opacity-30 border border-white w-full mt-8"/>
                <div className="text-right w-full text-white mt-4 text-sm">
                    © Paul Bartzsch 2021 | All rights reserved
                </div>
            </div>
        </>
    );
};

export default Footer;