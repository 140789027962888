const Tools = () => {
    return (
        <>
            <div className="w-full mt-24 lg:hidden">
                <div className="text-center text-gold2 text-l font-bold">Werkbank</div>
                <div className="w-full h-max mt-4 mx-auto">
                    <div className="px-6 md:px-20 mx-auto">
                        <div className="block mx-auto w-full sm:w-max mt-6">
                            <div className="grid grid-rows-4 sm:grid-cols-2">
                                <img src="/media/PB_Logo_Adobe.png" className="mx-auto" alt="Adobe"/>
                                <img src="/media/PB_Logo_OBS.png" className="mx-auto mt-4 sm:mt-0 sm:ml-10" alt="Open Broadcaster Software"/>
                                <img src="/media/PB_Logo_Windows.png" className="mx-auto mt-4" alt="Microsoft Windows"/>
                                <img src="/media/PB_Logo_Reaper.png" className="mx-auto mt-4 sm:ml-10" alt="Reaper"/>
                            </div>
                            <div className="text-left text-2xl text-gold mt-4 sm:mt-0 sm:-mt-24">Cave, Gear & Tools</div>
                            <div className="max-w-fit-content text-gold2 text-left mt-4">
                                Großzügig eingerichtetes Setup für Pre- und Postproduktion sowie Livestreaming mit allen heute gängigen Programmen und Devices.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="w-full 2xl:w-5.5/12 h-full hidden lg:block 2xl:mx-auto mt-48">
                <div className="grid grid-cols-2 mx-24 2xl:mx-auto">
                    <div className="grid grid-cols-2">
                        <img src="/media/PB_Logo_Adobe.png" alt="Adobe"/>
                        <img src="/media/PB_Logo_OBS.png" className="2xl:ml-0 3xl:-ml-12" alt="Open Broadcaster Software"/>
                        <img src="/media/PB_Logo_Windows.png" alt="Microsoft Windows"/>
                        <img src="/media/PB_Logo_Reaper.png" className="2xl:ml-0 3xl:-ml-12" alt="Reaper"/>
                    </div>
                    <div className="ml-12">
                        <div className="w-max text-l font-bold text-gold2">Werkbank</div>
                        <div className="w-max mt-4 text-3xl text-xl text-gold">Cave, Gear & Tools</div>
                        <div className="text-xl mt-4 text-gray-400">
                            <span className="text-base leading-6 break-words text-gold2">
                                Großzügig eingerichtetes Setup für Pre- und Postproduktion sowie Livestreaming mit allen heute gängigen Programmen und Devices.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Tools;