const Header = () => {
    return (
        <>
            <div className="h-16 w-full lg:h-12 top-0 left-0 fixed z-30 bg-menubg backdrop-filter backdrop-blur-md">
                    <div id="inner-menu" className="mx-auto h-16 lg:h-12 w-full px-16 2xl:w-8/12 flex">
                        <div className="my-auto">
                            <button className="lg:w-36">
                                <label className="text-gold text-3xl ml-8 lg:ml-0 lg:text-xl">Paul Bartzsch</label>
                            </button>
                        </div>
                        <div className="flex-grow">

                        </div>
                        <div className="my-auto mr-0 ml-auto">
                            <a href="#contact-form">
                                <button className="md:w-28">
                                    <label className="text-white cursor-pointer text-2xl lg:text-sm">Contact</label>
                                </button>
                            </a>
                        </div>
                        <div className="invisible md:visible my-auto mr-4">
                            <img className="m-auto w-full" src="/media/PB_Logo_klein.png" alt="Paul Bartzsch Logo klein"/>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default Header;