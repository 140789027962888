import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 767},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 767, min: 0},
        items: 1,
    },
};


const CarouselSliderClients = (props) => {
    return (
        <>
            <Carousel
                responsive={responsive}
                autoPlaySpeed={props.settings.autoPlaySpeed}
                autoPlay={props.settings.autoPlay}
                arrows={props.settings.arrows}
                infinite={props.settings.infinite}
                transitionDuration={props.settings.transitionDuration}
                itemClass={props.settings.itemClass}
                showDots={props.settings.showDots}
            >
                {props.data.map(({image_url, title, subtitle, subsubtitle, text, url}, index) => {
                    return (
                        <a href={url} rel="noreferrer" target="_blank">
                            <div key={index} className="mx-12">
                                <img src={image_url} rel="noreferrer" alt={title} style={{width: `100%`, height: `100%`, position: `relative`, zIndex: -2}}/>
                                <div className="mx-auto w-max -mt-12 font-medium mb-3 text-gold2">{title}</div>
                                <div className="mx-auto w-48 h-6 -mt-2 text-center text-sm text-gold2">{subtitle}</div>
                                <div className="mx-auto w-48 h-6 text-center -mt-2 mb-4 text-sm text-gold2">{subsubtitle}</div>
                                <div className="mx-auto w-max text-gold2 -mt-4">- - -</div>
                                <div className="text-gold2 text-center">{text}</div>
                            </div>
                        </a>
                    );
                })}
            </Carousel>;
        </>
    );
};

export default CarouselSliderClients;