const ClientsGenerator = (props) => {
    return (
        <>
            {props.data.map(({image_url, text, title, subtitle, subsubtitle, url}) => (
                    <>
                        <div className="w-56">
                            <a href={url} rel="noreferrer" target="_blank">
                            <div className="w-56 h-72">
                                <img src={image_url} alt={title}/>
                                <div className="w-max mx-auto -mt-7 font-medium mb-3 text-gold2">{title}</div>
                                <div className="mx-auto w-48 h-6 -mt-2 text-center text-sm text-gold2">{subtitle}</div>
                                <div className="mx-auto w-48 h-6 text-center -mt-2 text-sm text-gold2">{subsubtitle}</div>
                                <div className="mx-auto w-max text-gold2 -mt-1">- - -</div>
                            </div>
                            <div className="text-gold2 text-center mx-2 mt-0">{text}</div>
                            </a>
                        </div>
                    </>
                )
            )}
        </>
    );
};
export default ClientsGenerator;