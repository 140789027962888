const AboutMe = () => {
    return (
        <>
            <div className="w-full mt-24 lg:hidden">
                <div className="text-center text-gold2 text-l font-bold">Hi, Ich bin Paul</div>
                <div className="w-full h-max mt-4 mx-auto">
                    <div className="px-4 max-w-max mx-auto">
                        <img src="/media/PB_Bild_02.png" className="max-w-full max-h-full mx-auto" alt="Paul Bartzsch an der Kamera"/>
                        <div className="visible mt-4 text-left text-2xl text-gold">Who is this Guy</div>
                        <div className="max-w-fit-content text-gold2 text-left mt-4">
                            1988 in Brandenburg geboren und seit einigen Jahren in Berlin lebend,
                            bin ich mit frech-fröhlichem Gemüt seit 2009 freiberuflich als
                            Kreativitätsgeplagter unterwegs und zusätzlich seit 2019
                            fest in einer Produktionsfirma angestellt.
                            <img src="/media/Cutter_Icon.png" className="mt-2" alt="Cutter Icon"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full mt-12 hidden lg:block 2xl:w-5.5/12 2xl:mx-auto">
                <div className="grid grid-cols-2 mx-12 2xl:mx-auto">
                    <div className="mr-12 ml-12">
                        <div className="w-max text-l font-bold text-gold2">Hi, Ich bin Paul</div>
                        <div className="w-max mt-4 text-3xl text-xl text-gold">Who is this Guy</div>
                        <div className="text-xl mt-4 text-gray-400">
                            <span className="text-base leading-6 break-words text-gold2">
                                1988 in Brandenburg geboren und seit einigen Jahren in Berlin lebend,
                                bin ich mit frech-fröhlichem Gemüt seit 2009 freiberuflich als
                                Kreativitätsgeplagter unterwegs und zusätzlich seit 2019
                                fest in einer Produktionsfirma angestellt.
                            </span>
                            <img src="/media/Cutter_Icon.png" className="mt-4" alt="Cutter Icon"/>
                        </div>
                    </div>
                    <div className="w-full">
                        <img src="/media/PB_Bild_02.png" className="max-w-full max-h-full mx-auto" alt="Paul Bartzsch an der Kamera"/>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AboutMe;