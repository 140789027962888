import CarouselSliderProjects from "./subcomponents/CarouselSliderProjects";

const data = [
    {
        image: '/media/PB_Video_Thumbnail_04.jpg',
        text: `Staiy / Mifcom`,
        url: "//www.youtube.com/watch?v=RIUeocCQNZk"
    },
    {
        image: '/media/PB_Video_Thumbnail_02.png',
        text: `Timeprints / Humboldtforum`,
        url: "//www.youtube.com/watch?v=lMzEWrC4tE8"
    },
    {
        image: '/media/PB_Video_Thumbnail_03.png',
        text: `Deutsche Aidshilfe / Timeprints`,
        url: "//www.instagram.com/p/CWJP8ncD9Cn/"
    },
    {
        image: '/media/PB_Video_Thumbnail_04.png',
        text: `RiotGames / BlackSoup / Valorant`,
        url: "//www.youtube.com/watch?v=VR20VOvQdJg"
    },
];

const settings = {
    autoPlaySpeed: 5000,
    autoPlay: true,
    arrows: true,
    infinite: true,
    transitionDuration: 200,
    itemClass: "carousel-item",
    showDots: false,
};


const Projects = () => {
    return (
        <>
            <div className="w-full bg-graypb">
                <div className="h-full mx-auto pt-24">
                    <div className="h-full">
                        <div className="text-center text-4xl font-bold text-gold">Some Projects</div>
                        <div className="h-full w-full 2xl:w-7/12 3xl:w-5.5/12 mx-auto mt-12 pb-12">
                            <CarouselSliderProjects data={data} settings={settings}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Projects;