import '../css/index.css';
import '../css/AboutMe.css';
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeaderVideo from "../components/HeaderVideo";
import AboutMe from "../components/AboutMe";
import Services from "../components/Services";
import Tools from "../components/Tools";
import Projects from "../components/Projects";
import Clients from "../components/Clients";
import Contact from "../components/Contact";

function Home() {
    return (
        <div id="Home" className="App">
            <Header/>
            <HeaderVideo/>
            <Services/>
            <div id="aboutme-bg" className="">
            <AboutMe/>
            <Tools/>
            </div>
            <Projects/>
            <Clients/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Home;
