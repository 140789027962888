const Services = () => {
    return (
        <>
            <div className="w-full mt-16 lg:hidden">
                <div className="text-center text-gold2 text-l font-bold">Was ich anbiete</div>
                <div className="w-full h-max mt-4 mx-auto">
                    <div className="px-4 max-w-max mx-auto">
                        <img src="/media/PB_Bild_01.png" className="max-w-full max-h-full mx-auto" alt="Paul Bartzsch Profilfoto"/>
                        <div className="visible mt-4 text-left text-2xl text-gold">Services</div>
                        <div className="max-w-fit-content text-gold2 text-left mt-4">
                            Flexibel und Wunschgerecht, von der Idee bis zum Abschluss in diesen Bereichen kann ich unterstützen.
                        </div>
                    </div>
                    <div className="text-center lg:text-left">
                        <div className="mt-8 text-2xl text-gold2 break-all">Filmmaking & Video Editing</div>
                        <div className="mt-6 text-2xl text-gold2 break-all">Colorgrading</div>
                        <div className="mt-6 text-2xl text-gold2 break-all">Sounddesign</div>
                        <div className="mt-6 text-2xl text-gold2 break-all">Graphics & Overlays</div>
                        <div className="mt-6 text-2xl text-gold2 break-all">Conceptdevelopment</div>
                    </div>
                </div>
            </div>

            <div className="w-full mt-12 hidden lg:block 2xl:w-5.5/12 2xl:mx-auto">
                <div className="grid grid-cols-2 mx-12 2xl:mx-auto">
                    <div className="w-full">
                        <img src="/media/PB_Bild_01.png"  className="max-w-full h-auto" alt="Paul Bartzsch Profilfoto"/>
                    </div>
                    <div className="ml-12 3xl:ml-16">
                        <div className="w-max text-l font-bold text-gold2">Was ich anbiete</div>
                        <div className="w-max mt-4 text-3xl text-xl text-gold">Services</div>
                        <div className="text-xl mt-4 text-gray-400">
                            <span className="text-base leading-6 break-words text-gold2">
                                Flexibel und Wunschgerecht, von der Idee bis zum Abschluss in diesen Bereichen kann ich unterstützen.
                            </span>
                        </div>
                        <div className="mt-8 text-2xl text-gold2">Filmmaking & Video Editing</div>
                        <div className="mt-6 text-2xl text-gold2">Colorgrading</div>
                        <div className="mt-6 text-2xl text-gold2">Sounddesign</div>
                        <div className="mt-6 text-2xl text-gold2">Graphics & Overlays</div>
                        <div className="mt-6 text-2xl text-gold2">Conceptdevelopment</div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Services;